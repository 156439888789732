/**
  * 导出指令csv文件
  * tableData为表格数据，格式为[{id: '1', name: '派大星'}, {id: '2', name: '章鱼哥'}]
  * headData为表头，格式为[{'id': id, 'name': name}]
  * fileName为文件名
  */

export const exportToCsv = (tableData, headData, fileName) => {
  // 将表头数据转换为CSV格式的字符串
  const headerRow = Object.keys(headData).join(",") + "\n";

  // 将表格数据转换为CSV格式的字符串
  const rows = tableData
    .map((row) => {
      return Object.values(row).join(",") + "\n";
    })
    .join("");

  // 创建Blob对象，将CSV格式的字符串作为参数传入
  const blob = new Blob(["\ufeff" + headerRow + rows], { type: "text/csv;charset=utf-8" });
  // 创建隐藏的a标签，将Blob对象设置为其href属性，并设置下载属性为文件名
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // 触发点击事件，实现下载功能
  link.click();
}