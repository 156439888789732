<template>
  <div class="info-div">
    <div class="table-head-icon" style="float: right;">
      <el-tooltip content="筛选列" placement="top" >
        <el-dropdown trigger="click" :hide-on-click="false">
            <el-icon :size="18" style="cursor: pointer; border: 1px solid lightgray; margin-left: 8px; padding: 3px;"><Operation /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <template v-for="item in datas">
                  <el-dropdown-item v-if="item.type != 'checkbox'"><el-checkbox v-model="item.show" :label="item.label" size="small" /></el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </template>
        </el-dropdown>
      </el-tooltip>
      <el-tooltip content="导出" placement="top" >
        <el-dropdown trigger="click" :hide-on-click="false">
            <el-icon :size="18" style="cursor: pointer; border: 1px solid lightgray; margin-left: 8px; padding: 3px;"><FolderOpened /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="selectClick('csv')">导出到Csv文件</el-dropdown-item>
                <el-dropdown-item @click="selectClick('excel')">导出到Excel文件</el-dropdown-item>
              </el-dropdown-menu>
            </template>
        </el-dropdown>
        
      </el-tooltip>
      <el-tooltip content="打印" placement="top" >
        <el-icon :size="18" @click="selectClick('print')" style="cursor: pointer; border: 1px solid lightgray; margin-left: 8px; padding: 3px;"><Printer /></el-icon>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  ref,
  onMounted,
  reactive
} from 'vue'

// 父组件传参
const props = defineProps(['datas'])
const emit = defineEmits(['selectClick'])

// 导出到文件
const selectClick = (type) => {
  emit('selectClick', type)
}
</script>

<style lang="less">
.info-div {
  height: 26px;
  margin-bottom: 5px
}
</style>