// 打印
import printJS from "print-js";

export const exportToPrint = (id) => {
  const style = "@page {margin:0 10mm};"; //打印时去掉眉页眉尾
  //打印为什么要去掉眉页眉尾？因为眉页title时打印当前页面的title，相当于是获取html中title标签里面的内容，但是比如我打印的内容只是一个弹框里面的内容，是没有title的，这时候就会出现undefined，为了避免出现这种情况，就可以隐藏眉页眉尾
  printJS({
    printable: id, // 标签元素id
    type: "html",
    header: "",
    targetStyles: ["*"],
    style,
  });
  //各个配置项
  //printable:要打印的id。
  //type:可以是 html 、pdf、 json 等。
  //properties:是打印json时所需要的数据属性。
  //gridHeaderStyle和gridStyle都是打印json时可选的样式。
  //repeatTableHeader:在打印JSON数据时使用。设置为时false，数据表标题将仅在第一页显示。
  //scanStyles:设置为false时，库将不处理应用于正在打印的html的样式。使用css参数时很有用，此时自己设置的原来想要打印的样式就会失效，在打印预览时可以看到效果
  //targetStyles: [’*’],这样设置继承了页面要打印元素原有的css属性。
  //style:传入自定义样式的字符串，使用在要打印的html页面 也就是纸上的样子。
  //ignoreElements：传入要打印的div中的子元素id，使其不打印。非常好用
}